<template>
  <router-view></router-view>
</template>

<script>
import { mapGetters } from "vuex";
let utils = require("@/assets/global/js/utils.js");

export default {
  data: function () {
    return {
      pageTitle: "Manage Floor Plans"
    };
  }
};
</script>

<style lang="scss">
@import "./SettingsFloorplan.scss";
</style>
